// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Button from 'antd/lib/button'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/button/style/css'

import OutLink from '@bodhi-project/components/lib/OutLink'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Pay Fees Online',
  nakedPageSlug: 'pay-fees-online',
  pageAbstract:
    "To pay fees online you will need your child's Student ID. If you do not know your child's Student ID, please reach out to the school.",
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Pay Fees Online</h1>
    <div style={{ maxWidth: '60rem' }}>
      <p>
        To pay fees online you will need your child's Student ID. If you do not
        know your child's Student ID, please reach out to the school. Follow the
        URLs below to proceed to PayTM payment gateway.
        <br />
        URL for Fee Payment – <OutLink to="http://m.p-y.tm/cisgfee_web">http://m.p-y.tm/cisgfee_web</OutLink>
        <br />
        URL for New Registration – <OutLink to="http://m.p-y.tm/cisgnr_web">http://m.p-y.tm/cisgnr_web</OutLink>
        <br />
        <br />
        <strong> For any clarifications regarding fee, kindly visit school. </strong>
      </p>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
